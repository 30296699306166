body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

 
.responsive {
  width: 100% !important;
  height: auto !important;
}



.featured-box {
  background: #f5f5f5;
  background-color: transparent;
  background: -webkit-linear-gradient(top,white 1%,#f9f9f9 98%) repeat scroll 0 0 transparent;
  background: linear-gradient(to bottom,white 1%,#f9f9f9 98%) repeat scroll 0 0 transparent;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-bottom: 1px solid #dfdfdf;
  border-left: 1px solid #ececec;
  border-radius: 8px;
  border-right: 1px solid #ececec;
  box-shadow: 0 1px 1px 0 rgba(0,0,0,0.04);
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: 10px;
  margin-top: 20px;
  min-height: 100px;
  position: relative;
  text-align: center;
  z-index: 0;
}

/* overite for the cards */
/*xs and sm*/
  @media (max-width:600px)  { 
    h5  { font-size: 0.75rem!important;} 
    h6  { font-size: 0.55rem!important;} 
    .logo{ font-size: 1rem!important;}  



    .featured-box {
      background: #f5f5f5;
      background-color: transparent;
      background: -webkit-linear-gradient(top,white 1%,#f9f9f9 98%) repeat scroll 0 0 transparent;
      background: linear-gradient(to bottom,white 1%,#f9f9f9 98%) repeat scroll 0 0 transparent;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      border-bottom: 1px solid #dfdfdf;
      border-left: 1px solid #ececec;
      border-radius: 8px;
      border-right: 1px solid #ececec;
      box-shadow: 0 1px 1px 0 rgba(0,0,0,0.04);
      margin-bottom: 20px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 20px;
      min-height: 100px;
      position: relative;
      text-align: center;
      z-index: 0;
    }


  }

  
  /*sm*/
  @media (max-width:960px) and (min-width:601px) {
    h5  { font-size: 1.0rem!important;} 
    h6  { font-size: 0.85rem!important;} 
  }

  /*md*/
  @media (max-width:1280px) and (min-width:961px) {
    h5  { font-size: 1.1rem!important;} 
    h6  { font-size: 0.9rem!important;} 
  }
/* end overite for the cards */
   

.featureTitle > h5 { font-size: 0.95rem!important;} 
.featureTitle > h6  { font-size: 0.85rem!important;}  
 
.goBottomParrent > h5 { font-size: 1.2rem!important;} 
.goBottomParrent > h6  { font-size: 0.75rem!important;} 


 
/* privcing */
.priceCircle{
  background: #FFF;
  border: 5px solid #FFF;
  border-radius: 100px;
  box-shadow: 0 5px 20px #ddd inset, 0 3px 0 #999 inset;
  color: #777;
  display: block;
  font: bold 34px / 100px Georgia,Serif; 
 
  height: 100px;
  margin: -55px auto -65px;
  width: 100px;
  text-align: center; 
 }

 .pricingH3{
  background-color: #eee;
  border-radius: 2px 2px 0 0;
  font-size: 16px;
  font-weight: normal;
  margin: -20px -20px 50px -20px;
  padding: 20px;
 }

 .pricingSave{
  text-align: center;  
  margin-top:30px;  
 }

.pricingLI{
  font-size: 0.5rem;
  vertical-align:middle
  /* background-color: #08c; */
 }




/*end privcing */

.list-unstyled {
  padding-left: 0;
  list-style: none; 
}
ul, ol {
  margin-top: 0;
  margin-bottom: 10px;
}
 
ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

.textLeft   li{
  text-align: left;
}

.PricingBoxLi li{
  height: 30px;
    line-height: 30px;
    margin-left:40px;
 text-align: left;
}

hr{
  background-color: 1px solid rgba(0,0,0,.045);
  background-image: -webkit-linear-gradient(left,transparent,rgba(0,0,0,0.2),transparent);
    background-image: linear-gradient(to right,transparent,rgba(0,0,0,0.2),transparent);
    border: 0;
    height: 1px;
    margin: 22px 0 22px 0;
}


h3 {
  color: #CCC;
  font-size: 1.8em;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 24px;
  margin-bottom: 33px;
  text-transform: uppercase;
}

h3, h4, h5 {
  color: #08c;
}


 
@media (min-width: 992px){
.col-md-4 {
    width: 33.33333333%;
}
}

@media (min-width: 992px){
.col-md-8 {
    width: 66.66666667%;
}
}

@media (min-width: 992px){
.col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    float: left;
}
}


.featured-box-secundary .box-content {
  border-top-color:#ffc5e1;
}
.featured-box-secundary .box-content {
  border-top-color: #CCC;
}
.featured-box .box-content {
  border-radius: 8px;
  border-top: 3px solid #ffc5e1;
  border-top-width: 4px;
  padding: 20px 15px 10px 15px;
  width: 100%;
}

.featured-box h4 {
  font-size: 1.3em;
  font-weight: 400;
  letter-spacing: -0.7px;
  margin-top: 5px;
  text-transform: uppercase;
}

.clearfix {
  display: inline-block;
}

@media (min-width: 992px){
.col-md-6 {
    width: 50%;
}
}
@media (min-width: 992px){
.col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    float: left;
}
}
.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
    position: relative;
    min-height: 1px;
    padding-left: 0px;
    padding-right: 0px;
}
.formG{
  padding-right:15px;
}

form {
  display: block;
  margin-top: 0em;
  margin-left:-10px;
}
.form-group {
  margin-bottom: 15px;
}

.form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  -webkit-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}


.MuiTypography-h5 {
  font-size: 1.1rem !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.334;
  letter-spacing: 0em;
  
}

.MuiTypography-h6 {
  font-size: 1rem !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400 !important;
  line-height: 1.6;
  letter-spacing: 0.0075em;
}

  a
{
  color:#003660!important;
  color:#005594!important;
  text-decoration: none!important;
}